.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header {
  background-color: #fff;
  box-shadow: 0 2px 10px black !important;
  display: flex; /* enable flex Layout */
  align-items: center; /* vertically center logo and menu */
  justify-content: space-between; /* separate logo and menu */
  padding: 0 22px; /* add padding to separate logo and menu from edges */
}

.logo {
  width: 200px; /* set the width of the logo as needed */
  height: 40px; /* set the height of the logo as needed */
  background-image: url('assets/icons/logo.png'); /* set the path to your logo */
  background-size: contain; /* adjust the size of the logo as needed */
  background-repeat: no-repeat; /* prevent the logo from repeating */
}

.menuWrapper {
  width:28%;
  display: flex; /* enable flex Layout */
  align-items: baseline; /* vertically center menu items */
  justify-content: center;
}

.ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-submenu-open .ant-menu-item-selected {
  background-color: #E9F0FD !important;
  color: #377EF3 !important;
}
.ant-menu-item:first-child {
  margin-top: 10px;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}
.ant-picker-cell-selected>.ant-picker-cell-inner{
  border-radius: 50% !important;
}
.ant-picker-cell-inner::before{
  border: none !important;
}
.ant-btn>div{
  display: none
}
.ant-modal-header{
  margin-bottom: 15px !important;
}
.ant-modal-title{
  font-weight: 600 !important;
  font-size: 18px !important;
}

.ant-upload{
  width: 100% !important;
}

.selected-row{
  background-color: #E9F0FD !important;
  color: #377EF3 !important;
}
.ant-table-cell-row-hover{
  background-color: transparent !important;
}
/* Remove any cursor indication on hover for disabled button */
button:disabled {
  cursor: auto;
}

/* Remove any cursor indication on hover for disabled Ant Design button */
.ant-btn:disabled {
  cursor: auto;
}


/* Remove any cursor indication on hover for disabled input */
input:disabled {
  cursor: auto;
}

/* Remove any cursor indication on hover for disabled Ant Design input */
.ant-input:disabled {
  cursor: auto;
}

.ant-upload[aria-disabled="true"] .ant-input[aria-disabled="true"]:hover {
  cursor: auto;
}

/* Remove any cursor indication on hover for disabled Ant Design Icon */
.anticon[aria-disabled="true"] {
  cursor: auto;
}
.ant-form-item:disabled{
  cursor: auto;
}
.ant-form-item .ant-form-item-extra{
  min-height: 0px !important;
}

.ant-table {
  width: 100%;
  overflow-x: auto !important;
}

.custom-page-item {
  display: inline-block;
  padding: 0 8px;
  margin: 0 2px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  font-size: 1em;
  color: #377EF3;
  background-color: white;
  border: 0.1em solid #377EF3;
  border-radius: 5px;
  cursor: pointer;
}

.custom-page-item.current {
  font-weight: bold;
  color: black;
  border: 1px solid white !important;
  cursor: default;
  pointer-events: none;
}

.ant-pagination-item.ant-pagination-item-active {
  background: none !important;
  border-color: transparent !important;
  font-weight: normal !important;
  color: inherit !important;
}

.ant-pagination-item-link{
  color: #377EF3 !important;
}

.ant-pagination-options-size-changer{
  top: 2px !important;
}

.custom-select-dropdown {
  white-space: normal !important;
}

.custom-select-option {
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.card:hover .card-icon {
  background-color: rgb(233, 240, 253); /* Light background color */
}

.highlight-datatable-row-red .ant-table-cell {
  box-shadow: inset 0px -1px 0px 0px #DC000C; /* Top and bottom border */
  border-top: 1px solid #DC000C;
}
.highlight-datatable-row-red .ant-table-cell:first-child{
    border-left: 1px solid #DC000C;
}
.highlight-datatable-row-red .ant-table-cell:last-child{
    border-right: 1px solid #DC000C;
}


.highlight-datatable-row-yellow .ant-table-cell {
  box-shadow: inset 0px -1px 0px 0px #CE7F00; /* Top and bottom border */
  border-top: 1px solid #CE7F00;
}
.highlight-datatable-row-yellow .ant-table-cell:first-child{
    border-left: 1px solid  #CE7F00;
}
.highlight-datatable-row-yellow .ant-table-cell:last-child{
    border-right: 1px solid  #CE7F00;
}

.highlight-datatable-row-green .ant-table-cell{
    box-shadow: inset 0px -1px 0px 0px #079327; /* Top and bottom border */
    border-top: 1px solid #079327;
}
.highlight-datatable-row-green .ant-table-cell:first-child{
    border-left: 1px solid #079327;
}
.highlight-datatable-row-green .ant-table-cell:last-child{
    border-right: 1px solid #079327;
}

.card-edit-icon{
  box-shadow: none !important;
  background-color: transparent;
}
.bordered-default-button{
  border-color: #377EF3;
  color: #377EF3;
}
.primary-button{
  background: #377EF3;
}
.button-red-filled{
  background: #DC000C;
}
.button-cancel{
  border-color: #DC000C;
  color: #DC000C;
}

.addpage-warning-message {
  text-align: center;
  color: #DC000C;
  font-weight: bold;
  margin-top: 16px;
  padding: 12px;
  background-color: #fff5f5; /* Light red background for warning effect */
  border-radius: 4px;
  border: 1px solid #ffa39e; /* Slight border to highlight the warning */
}

.bucket-switch-button{
  border-color: #377EF3;
  color: #377EF3;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .ant-table {
    overflow-x: auto !important;
  }
}

@media (min-width: 769px) {
  .ant-table {
    overflow-x: hidden !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
